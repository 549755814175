import React from "react";

import { BottomCaption, Content, Section, Subtitle, Title, Wrapper } from "./components";

type PageProps = {
  title?: string;
  subtitle?: React.ReactNode | string;
  children: React.ReactNode;
  className?: string;
  bottomCaption?: string;
};

const DashboardPageTemplate: React.FC<PageProps> = ({ title, subtitle, children, className, bottomCaption }) => {
  return (
    <Wrapper className={className}>
      <Section>
        {title && <Title alignment="left">{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Content dashboard>{children}</Content>
        {bottomCaption && <BottomCaption>{bottomCaption}</BottomCaption>}
      </Section>
    </Wrapper>
  );
};

export default DashboardPageTemplate;
