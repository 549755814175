import Head from "next/head";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import { Footer } from "@/components/Footer";
import { DashboardHeader } from "@/components/Header";

type PageProps = {
  title: string;
  description: string;
};

export const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.black.main};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Page: React.FC<PropsWithChildren<PageProps>> = ({ title, description, children }) => {
  const TITLE = `${title} | Cover Re`;

  return (
    <>
      <Head>
        <title>{TITLE}</title>
        <meta name="title" content={TITLE} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.coverre.com" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/banner.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://app.coverre.com" />
        <meta property="twitter:title" content={TITLE} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content="/banner.png" />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Wrapper>
        <DashboardHeader />
        {children}
        <Footer />
      </Wrapper>
    </>
  );
};

export default Page;
