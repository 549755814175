import Link from "next/link";
import styled, { css } from "styled-components";

const UnstyledLinkStyle = css`
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
`;

export const UnstyledLink = styled(Link)`
  ${UnstyledLinkStyle}
`;

export const ExternalUnstyledLink = styled.a`
  ${UnstyledLinkStyle}
`;
