import NextLink, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";
import styled from "styled-components";

export const ExternalLink = styled.a`
  position: relative;
  white-space: nowrap;

  &:after {
    content: "";

    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    color: ${({ theme }) => theme.palette.white.main};

    border-width: 0 0 1px;
    border-style: solid;
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Link: React.FC<PropsWithChildren<LinkProps & { newTab?: boolean }>> = ({ newTab, ...props }) => (
  <NextLink {...props} passHref>
    <ExternalLink {...(newTab && { target: "_blank", rel: "noopener noreferrer" })}>{props.children}</ExternalLink>
  </NextLink>
);

// export const Link = NextLink;
