import styled from "styled-components";
import { Heading, Text } from "ui";

import { Section as _Section } from "@/components/Layout";

export const Wrapper = styled.div`
  padding-top: 144px;
  height: 100%;
  margin-bottom: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 110px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: 64px;
  }
`;

export const Section = styled(_Section)``;

export const Title = styled(Heading.H1)<{ alignment?: "left" | "center" }>`
  text-align: ${({ alignment }) => alignment || "center"};
  font-weight: 700;
`;

export const Subtitle = styled(Text.Large)`
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
  max-width: 700px;
`;

export const BottomCaption = styled(Text.Caption)`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
  margin: 48px auto;
  text-align: center;
  max-width: 55ch;
`;

export const Content = styled.div<{ dashboard?: boolean }>`
  margin-top: 24px;
  border-radius: 48px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  padding-block: 24px;
  padding-inline: ${({ dashboard }) => (dashboard ? "0" : "128px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    margin-top: 32px;
  }
`;
