import styled from "styled-components";

export const Section = styled.div`
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 56px;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 24px;
  }
`;
