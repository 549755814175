import styled, { css } from "styled-components";
import { Heading as _Heading, Link as _Link, Text } from "ui";

import { Image } from "@/components/Image";
import { Section as _Section } from "@/components/Layout";
import { ExternalUnstyledLink } from "@/components/Link";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 120px;
  width: 100%;
  padding: 32px 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.black.tint};
  border-radius: 60px 60px 0 0;
  margin-top: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 24px 0px;
    height: auto;
    border-radius: 32px 32px 0 0;
  }
`;

export const Section = styled(_Section)``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  padding-top: 48px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: center;
  }
`;

export const LogoSection = styled.div`
  width: 20%;
  margin-top: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const LogoSectionBottom = styled(LogoSection)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const MiddleSection = css`
  width: 40%;
  min-width: 275px;
  margin: 0 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin: 48px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0;
  }
`;

export const ItemsSection = styled.div`
  ${MiddleSection}
  height: 320px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    max-width: 500px;
    justify-content: flex-start;
    align-content: space-between;
  }
`;

export const DownloadSection = styled.div`
  width: 30%;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    align-self: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    margin-top: 64px;
  }
`;

export const ItemSection = styled.div`
  width: 196px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-right: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 164px;
    margin-right: 8px;
  }
`;

export const Heading = styled(_Heading.H3)`
  font-style: italic;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.white.main};
  margin-top: 24px;
`;

export const Link = styled(_Link)`
  margin: 4px 0;
`;

export const Item = styled(Text.Body)`
  color: ${({ theme }) => theme.palette.white.main};
  display: block;
`;

export const Download = styled(_Heading.H4)`
  color: ${({ theme }) => theme.palette.white.main};
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 24px;
  }
`;

export const StoreRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const GooglePlayStore = styled(ExternalUnstyledLink)`
  margin-right: 22px;
  margin-bottom: 8px;
`;

export const AppleAppStore = styled(ExternalUnstyledLink)`
  margin-bottom: 8px;
`;

export const Copyright = styled(Text.Caption)`
  ${MiddleSection}
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${({ theme }) => theme.palette.white.main};
  display: inline-flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: inline-block;
    margin-top: 64px;
  }
`;

export const Icons = styled.div`
  display: none;
  /* display: flex; */
  max-width: 168px;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const SocialIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const _CoverIcon = styled.div`
  display: none;
  width: 48px;
  height: 48px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CoverIcon: React.FC = () => (
  <_CoverIcon>
    <Image src="/assets/common/icon.svg" width={48} height={48} alt="Cover icon" priority />
  </_CoverIcon>
);

export const NavItem = styled(Text.Large)<{ $sticky?: boolean }>`
  color: ${({ theme }) => theme.palette.white.main};
  font-weight: 700;
  padding: 0 0.25em;
  line-height: 56px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 18px;
  line-height: 24px;
`;
