import React from "react";

type LetterLogoProps = {
  color: string;
  className?: string;
};

const LetterLogo: React.FC<LetterLogoProps> = ({ color, className }: LetterLogoProps) => (
  <svg
    width="30px"
    height="32px"
    viewBox="0 0 30 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>Path</title>
    <g id="Home-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Mobile-Nav-Menu" transform="translate(-19.000000, -16.000000)" fill={color} fillRule="nonzero">
        <g id="Mobile/header/menu">
          <path
            d="M34.8155708,24.3644752 C27.7361654,24.7185698 28.9604834,32.5467128 28.9604834,32.5467128 C29.4422383,36.3713956 32.3965462,38.8904268 36.6480617,39.0380623 C40.340377,39.1707036 43.3857969,37.9607843 44.9005345,37.0542099 L48.8399913,44.8973472 C46.6601358,46.2202999 41.5612783,48 36.1253063,48 C25.3000573,48 19.0725494,40.72203 19.0725494,31.5224913 C19.0725494,22.8477509 25.8854521,16 35.5319392,16 C43.201295,16 46.3401047,17.1856978 46.3697162,17.1972318 L46.3697162,17.1972318 L46.3697162,30.094579 C45.87885,30.2099193 45.1681761,30.3333333 44.7160327,30.3979239 C38.9566129,31.2226067 36.563783,27.9354095 36.4601431,24.3690888 C35.9124228,24.3379556 35.363453,24.3364156 34.8155708,24.3644752 L34.8155708,24.3644752 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default LetterLogo;
