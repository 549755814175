import NextImage from "next/image";
import React from "react";
import styled from "styled-components";

import { getSrc } from "@/utils/image";

const Wrapper = styled.div<{
  width: number;
  height: number;
  mobileWidth?: number;
  mobileHeight?: number;
  fadeInDuration?: number;
}>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  transition: opacity ${({ fadeInDuration = 0.25 }) => fadeInDuration}s;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: ${({ width, mobileWidth }) => mobileWidth || width}px;
    height: ${({ height, mobileHeight }) => mobileHeight || height}px;
  }
`;

type ImageProps = {
  src: string;
  width: number;
  height: number;
  alt?: string;
  mobileWidth?: number;
  mobileHeight?: number;
  quality?: number;
  layout?: "intrinsic" | "fill";
  objectFit?: "contain" | "cover" | "fill" | "none";
  objectPosition?: string;
  sizes?: string;
  priority?: boolean;
  loading?: "lazy" | "eager";
  className?: string;
  fadeInDuration?: number;
  onClick?: () => void;
};

const Image: React.FC<ImageProps> = React.forwardRef(
  (
    {
      src,
      width,
      height,
      alt,
      mobileWidth,
      mobileHeight,
      quality = 100,
      layout = "intrinsic",
      objectFit = "contain",
      objectPosition = "center",
      loading = "lazy",
      priority = false,
      sizes,
      className,
      fadeInDuration = 0.25,
      onClick,
    },
    ref,
  ) => {
    const [opacity, setOpacity] = React.useState(0);

    return (
      <Wrapper
        className={className}
        width={width}
        height={height}
        mobileWidth={mobileWidth}
        mobileHeight={mobileHeight}
        ref={ref as React.MutableRefObject<HTMLInputElement>}
        onClick={onClick}
        style={{ opacity }}
        fadeInDuration={fadeInDuration}
      >
        <NextImage
          src={getSrc(src)}
          layout={layout}
          width={layout === "fill" ? undefined : width}
          height={layout === "fill" ? undefined : height}
          objectFit={objectFit}
          objectPosition={objectPosition}
          quality={quality}
          sizes={sizes}
          alt={alt}
          priority={priority}
          loading={priority ? undefined : loading}
          onLoadingComplete={() => setOpacity(1)}
        />
      </Wrapper>
    );
  },
);

export default Image;
