import React from "react";

type MenuIconProps = {
  color: string;
  className?: string;
  onClick?: () => void;
};

const MenuIcon: React.FC<MenuIconProps> = ({ color, className, onClick }: MenuIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <title>Menu Icon</title>
    <path d="M4 12H20" stroke={color || "white"} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4 18H20" stroke={color || "white"} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M4 6H20" stroke={color || "white"} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default MenuIcon;
