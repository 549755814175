import { Action, ActionType } from "./action";
import type { HeaderState } from "./DashboardHeader";

export const reducer = (
  state: HeaderState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ActionType<any>,
): HeaderState => {
  switch (action.type) {
    case Action.SetScrollTop:
      return {
        ...state,
        menuOpen: false,
        scrollTop: action.payload,
        scrollUp: state.scrollTop > action.payload,
        sticky: action.payload > 500,
      };
    case Action.ToggleMenu:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
};
