import { useConnectWallet, useWallets } from "@web3-onboard/react";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useMediaQuery } from "react-responsive";
// import { isMobile } from "react-device-detect";
import styled, { ThemeContext } from "styled-components";
import { Dot } from "ui";

import { ExternalUnstyledLink, UnstyledLink } from "@/components/Link";
import { routes as external } from "@/routes/external";
import { initWeb3Onboard } from "@/utils/blocknative";

import { setScrollTop, toggleMenu } from "./action";
import {
  Actions,
  CloseButton,
  Container,
  Icons,
  Label,
  Logo,
  Menu,
  MenuButton,
  MenuItem,
  MobileNavLinks as _MobileNavLinks,
  Nav as _Nav,
  NavItem,
  // NotificationButton,
  Overlay,
  Section,
  SocialIcon,
  WalletButton,
  Wrapper,
} from "./components";
import { reducer } from "./reducer";
// import { initNotify, initWeb3Onboard } from "./services";

type RouteProps = {
  name: string;
  href: string;
};

const variants = {
  sticky: {
    position: "fixed",
    top: [-110, 0],
    backgroundColor: "#191919",
    delay: 0.5,
  },
  initial: {
    top: 0,
    position: "absolute",
  },
};

const routes: RouteProps[] = [
  {
    name: "Member",
    href: "/",
  },
  {
    name: "Syndicate",
    href: "/syndicates",
  },
  {
    name: "Auditor",
    href: "/auditors",
  },
];

type SocialMediaType = {
  icon: string;
  href: string;
  alt: string;
};

const socialMedia: SocialMediaType[] = [
  {
    icon: "/assets/footer/twitter.svg",
    href: external.twitter,
    alt: "Cover Twitter",
  },
  {
    icon: "/assets/footer/discord.svg",
    href: external.instagram,
    alt: "Cover Discord",
  },
  {
    icon: "/assets/footer/youtube.svg",
    href: external.facebook,
    alt: "Cover YouTube",
  },
  {
    icon: "/assets/footer/medium.svg",
    href: external.linkedin,
    alt: "Cover Medium",
  },
];

const Nav = styled(_Nav)`
  grid-template-columns: auto min-content 1fr;
  grid-gap: 64px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-gap: 24px;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, auto);
  }
`;

const MobileNavLinks = React.memo(() => {
  return (
    <_MobileNavLinks>
      {routes.map((route) => (
        <UnstyledLink href={route.href} key={route.href}>
          <MenuItem>{route.name}</MenuItem>
        </UnstyledLink>
      ))}
    </_MobileNavLinks>
  );
});

const SocialIcons = React.memo(() => (
  <Icons>
    {socialMedia.map((media) => (
      <ExternalUnstyledLink key={media.icon} href={media.href} target="_blank">
        <SocialIcon>
          <Image src={media.icon} width={24} height={24} alt={media.alt} priority />
        </SocialIcon>
      </ExternalUnstyledLink>
    ))}
  </Icons>
));

type NavLinksProps = {
  sticky: boolean;
};

const NavMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  /* justify-content: center; */
  grid-gap: 64px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-gap: 48px;
    display: none;
  }
`;

const NavLinks = React.memo<NavLinksProps>(({ sticky }) => {
  const router = useRouter();

  return (
    <NavMenu>
      {routes.map((route) => (
        <UnstyledLink href={route.href} key={route.href}>
          <NavItem active={router.pathname === route.href}>{route.name}</NavItem>
        </UnstyledLink>
      ))}
    </NavMenu>
  );
});

export type HeaderState = {
  menuOpen: boolean;
  scrollTop: number;
  scrollUp: boolean;
  sticky: boolean;
};

const initialState: HeaderState = {
  menuOpen: false,
  scrollTop: 0,
  scrollUp: false,
  sticky: false,
};

type HeaderProps = {
  lightMode?: boolean;
};

const DashboardHeader: React.FC<HeaderProps> = ({ lightMode }: HeaderProps) => {
  const themeContext = useContext(ThemeContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ wallet }] = useConnectWallet();
  const [truncatedAddress, setTruncatedAddress] = useState("No wallet connected");
  const isMobile = useMediaQuery({ query: `(max-width: ${themeContext.breakpoints.tablet})` });
  const connectedWallets = useWallets();

  const { menuOpen, scrollTop, scrollUp, sticky } = state;

  const setAddress = () => {
    const address = connectedWallets[0]?.accounts[0]?.address;

    if (!address) {
      setTruncatedAddress(isMobile ? "Not connected" : "No wallet connected");
    } else if (isMobile) {
      setTruncatedAddress("..." + address.substring(address.length - 4));
    } else {
      setTruncatedAddress(address.substring(0, 6) + "..." + address.substring(address.length - 4));
    }
  };

  useEffect(() => {
    initWeb3Onboard;
    setAddress();
  }, []);

  useEffect(() => {
    const handleScroll = (e: any) => {
      const newScrollTop = e?.target?.documentElement?.scrollTop || 0;
      dispatch(setScrollTop(newScrollTop));
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollTop]);

  useEffect(() => {
    setAddress();
  }, [isMobile, connectedWallets]);

  return (
    <>
      {menuOpen && <Overlay />}
      <Container
        sticky={sticky}
        initial="initial"
        animate={sticky ? "sticky" : scrollTop < 110 ? "initial" : "hide"}
        transition={{ ease: "easeOut", duration: 0.25 }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        variants={variants as any}
      >
        <Section>
          <Wrapper>
            <Nav>
              <UnstyledLink href="/">
                <div>
                  <Logo />
                </div>
              </UnstyledLink>
              <NavLinks sticky={sticky || !lightMode} />
              <Actions>
                {/* <NotificationButton disabled>
                  <Notification />
                </NotificationButton> */}
                {!isMobile && <Label>Avalanche</Label>}
                <WalletButton className="wallet-button" disabled={wallet === null} icon={<Dot />} iconPosition="left">
                  {truncatedAddress}
                </WalletButton>
                <CloseButton
                  $scrollUp={scrollUp || !sticky}
                  menuOpen={menuOpen}
                  color={themeContext.palette.white.main}
                  onClick={() => dispatch(toggleMenu())}
                />
                <MenuButton
                  $scrollUp={scrollUp || !sticky}
                  menuOpen={menuOpen}
                  color={themeContext.palette.white.main}
                  onClick={() => dispatch(toggleMenu())}
                />
              </Actions>
            </Nav>
          </Wrapper>
        </Section>
        <Menu open={menuOpen} isMobile={isMobile} sticky={sticky}>
          <MobileNavLinks />
          <SocialIcons />
        </Menu>
      </Container>
    </>
  );
};

export default DashboardHeader;
