import React from "react";
import styled from "styled-components";
import { Logo as _Logo } from "ui";

import { Image } from "@/components/Image";
import { routes as internalRoutes } from "@/routes/";
import { routes as external } from "@/routes/external";

import { ExternalUnstyledLink, UnstyledLink } from "../Link";
import { Icons, NavItem, Section, SocialIcon, Wrapper } from "./components";

const Logo = styled(_Logo)`
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    justify-self: center;
  }
`;

type RouteProps = {
  name: string;
  href: string;
};

const routes: RouteProps[] = [
  {
    name: "Whitepaper",
    href: internalRoutes.whitepaper,
  },
  {
    name: "Contact",
    href: internalRoutes.contact,
  },
  {
    name: "FAQ",
    href: internalRoutes.faq,
  },
];

type SocialMediaType = {
  icon: string;
  href: string;
  alt: string;
};

const socialMedia: SocialMediaType[] = [
  {
    icon: "/assets/footer/twitter.svg",
    href: external.twitter,
    alt: "Cover Twitter",
  },
  {
    icon: "/assets/footer/discord.svg",
    href: external.instagram,
    alt: "Cover Discord",
  },
  {
    icon: "/assets/footer/youtube.svg",
    href: external.facebook,
    alt: "Cover YouTube",
  },
  {
    icon: "/assets/footer/medium.svg",
    href: external.linkedin,
    alt: "Cover Medium",
  },
];

const Nav = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr 168px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 36px;
    text-align: center;
  }
`;

const NavLinksStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  justify-content: center;
  grid-gap: 64px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-gap: 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const NavLinks = React.memo(() => (
  <NavLinksStyles>
    {routes.map((route) => (
      <UnstyledLink href={route.href} key={route.href}>
        <NavItem>{route.name}</NavItem>
      </UnstyledLink>
    ))}
  </NavLinksStyles>
));

const SocialIcons = React.memo(() => (
  <Icons>
    {socialMedia.map((media) => (
      <ExternalUnstyledLink key={media.icon} href={media.href} target="_blank">
        <SocialIcon>
          <Image src={media.icon} width={24} height={24} alt={media.alt} priority />
        </SocialIcon>
      </ExternalUnstyledLink>
    ))}
  </Icons>
));

const Footer = () => {
  return (
    <Wrapper>
      <Section>
        <Nav>
          <UnstyledLink href={internalRoutes.home}>
            <>
              <Logo />
            </>
          </UnstyledLink>
          <NavLinks />
          <SocialIcons />
        </Nav>
      </Section>
    </Wrapper>
  );
};

export default Footer;
