export const isProd = process.env.NEXT_PUBLIC_ENV === "production";
export const isStaging = process.env.NEXT_PUBLIC_ENV === "staging";
export const isDevelopment = process.env.NEXT_PUBLIC_ENV === "development";
export const isTest = process.env.NEXT_PUBLIC_ENV === "test";
export const rCmpEbi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function balanceOf(address) view returns (uint)",
  "function getPricePerFullShare() external view returns (uint256)",
  "function underlyingBalanceInPool() external view returns (uint256)",
  "function deposit(uint256 assets, address receiver) external returns (uint256 shares)",
  "function approve(address spender, uint256 value) external returns (bool)",
  "function registerQSRP(address qsrp, bool status) external",
  "function returnInvestment(address qsrp, uint amount) external",
  "function scheduleWithdraw(uint256 assets) external",
  "function scheduleEmergencyWithdraw(uint256[] depositIds) external",
  "function updateDepositFeeNominator(uint16 depositFeeNominator) external",
  "function updateGlobalWithdrawTimelock(uint globalTimelock) external",
  "function updateInvestmentAllowance(bool isInvestmentAllowed) external",
  "function updateInvestmentBalance(address qsrp, uint amount, uint realEventTime) external",
  "function updateTreasuryAddress(address treasuryAddress) external",
  "function updateWithdrawFee(uint newFee) external",
  "function updateWithdrawWaitingPeriod(uint withdrawWaitingPeriod) external",
  "function withdraw(uint256 assets, address receiver, address owner) external returns (uint256 shares)",
  "function withdrawInvestmentToQSRP(address qsrp, uint amount) external",
];
