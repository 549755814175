export enum Action {
  SetScrollTop = "SET_SCROLL_TOP",
  ToggleMenu = "TOGGLE_MENU",
}

export type ActionType<T> = {
  type: string;
  payload: T;
};

export const setScrollTop = (scrollTop: number): ActionType<number> => ({
  type: Action.SetScrollTop,
  payload: scrollTop,
});

export const toggleMenu = (): ActionType<void> => ({
  type: Action.ToggleMenu,
  payload: undefined,
});
