export const routes = {
  careers: "https://jobs.lever.co/cover-2",
  appStore: "https://apps.apple.com/US/app/id1021671617",
  playStore: "https://play.google.com/store/apps/details?id=com.cover.android",
  googlePrivacy: "https://policies.google.com/privacy",
  googleTerms: "https://policies.google.com/terms",
  plaidLegal: "https://plaid.com/legal",
  stripePrivacy: "https://stripe.com/us/privacy",
  stripeLegal: "https://stripe.com/us/checkout/legal",
  instagram: "https://www.instagram.com/usecover/",
  facebook: "https://www.facebook.com/cover",
  twitter: "https://twitter.com/cover",
  linkedin: "https://www.linkedin.com/company/cover-./mycompany",
};
