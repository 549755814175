export const routes = {
  home: "/",
  about: "/about-us",
  contact: "/contact",
  joinAsSyndicate: "/contact/join-as-syndicate",
  becomeAnAuditor: "/contact/become-an-auditor",
  submitAProgram: "/contact/submit-a-program",
  privacy: "/privacy-policy",
  terms: "/terms",
  faq: "/faq",
  careers: "/careers",
  claims: "/claims",
  download: "/download",
  download_ios: "/download/ios",
  download_android: "/download/android",
  agents: "/for-agents",
  licenses: "/licenses",
  blog: "/blog",
  insurance: "/insurance",
  calculator: "/calculator",
  apiAccess: "/api-access",
  whitepaper: "https://files.coverre.com/docs/Re-Whitepaper.pdf",
};
