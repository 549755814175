import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { SecondaryButton, Text } from "ui";

import { Section as _Section } from "@/components/Layout/Section";
import { Overlay as _Overlay } from "@/components/Overlay";

import _CloseIcon from "./CloseIcon";
import _LetterLogo from "./LetterLogo";
import _Logo from "./Logo";
import _MenuIcon from "./MenuIcon";

export const Overlay = styled(_Overlay)`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Container = motion(styled.div<{ sticky?: boolean }>`
  position: absolute;
  width: 100%;
  height: 110px;
  z-index: 11;

  border-radius: 0 0 60px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ sticky }) => (sticky ? "0px 2px 12px 0px rgba(0, 0, 0, 0.1)" : "none")};

  @supports (backdrop-filter: blur(48px)) or (-webkit-backdrop-filter: blur(48px)) {
    background-image: ${({ sticky }) =>
      sticky && `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(0, 0, 0, 0.6)`};
    -webkit-backdrop-filter: ${({ sticky }) => sticky && `blur(48px)`};
    backdrop-filter: ${({ sticky }) => sticky && `blur(48px)`};
    background-color: ${({ sticky }) => (sticky ? "transparent" : "rgba(0, 0, 0, 1)")} !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 64px;
    border-radius: 0px 0px 32px 32px;
  }
`);

export const Section = styled(_Section)`
  margin: 16px auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 56px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .wallet-button {
    &:disabled svg circle {
      fill: currentColor;
    }
  }
`;

export const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: unset;
  }
`;

export const Logo = styled(_Logo)<{ sticky?: boolean }>`
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: ${({ sticky }) => (sticky ? "none" : "inline-block")};
  }
`;

export const LetterLogo = styled(_LetterLogo)<{ sticky?: boolean }>`
  display: none;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: ${({ sticky }) => (sticky ? "inline-block" : "none")};
  }
`;

export const NavItem = styled(Text.Large)<{ active?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white.main};
  padding: 0 0.25em;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.palette.black.main + theme.opacity.none};
  ${({ active }) =>
    active &&
    css`
      border-bottom-color: ${({ theme }) => theme.palette.mint.main};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    border-bottom-color: ${({ theme }) => theme.palette.mint.main};
  }
`;

export const GetAppButton = styled(SecondaryButton)<{
  $sticky?: boolean;
  $scrollUp?: boolean;
}>`
  box-shadow: none;
  padding: 0;
  justify-self: end;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-left: 0;
    visibility: ${({ $scrollUp }) => $scrollUp && "hidden"};
    display: ${({ $sticky, $scrollUp }) => (!$scrollUp && $sticky ? "block" : "none")};
    line-height: 16px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: ${({ theme, disabled }) => !disabled && theme.palette.white.main};
    border: none;
  }
`;

export const Menu = styled.div<{ open?: boolean; isMobile?: boolean; sticky?: boolean }>`
  display: none;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    ${({ open, isMobile, sticky }) => {
      if (open && isMobile && !sticky) {
        return css`
          // height: 100%; /* Fallback */
          // height: fill-available;
          // height: stretch; /* Latest specification */
          height: calc(100vh - calc(100vh - 100%));
        `;
      } else if (open && (!isMobile || sticky)) {
        return css`
          height: 100vh;
        `;
      } else {
        return css`
          height: 0;
        `;
      }
    }}
    justify-content: center;

    background-color: ${({ theme }) => theme.palette.black.main};
    opacity: ${({ open }) => (open ? 1 : 0)};
    position: fixed;
    top: 0;
    padding-top: 64px;
    z-index: -10;
    display: ${({ open }) => (open ? "grid" : "none")};
    grid-gap: 24px;

    align-items: center;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
`;

export const NavButton = css`
  display: none;
  width: 32px;
  height: 32px;
  align-self: center;
  cursor: pointer;
`;

export const CloseButton = styled(_CloseIcon)<{
  $scrollUp?: boolean;
  menuOpen?: boolean;
}>`
  ${NavButton}

  width: 24px;
  height: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: ${({ menuOpen }) => menuOpen && "inline-block"};
  }
`;

export const MenuButton = styled(_MenuIcon)<{
  $scrollUp?: boolean;
  menuOpen?: boolean;
}>`
  ${NavButton}

  width: 24px;
  height: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: ${({ menuOpen }) => !menuOpen && "inline-block"};
  }
`;

export const MenuItem = styled(Text.Body)`
  margin: 8px 0;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  cursor: pointer;
`;

export const Label = styled(Text.Body)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const Icons = styled.div`
  display: none;
  /* display: flex; */
  align-items: center;
  padding: 20px 24px;
  height: 64px;
  width: 100%;
  border-radius: 32px 32px 0px 0px;
  justify-content: space-between;
  margin: auto auto 0;
  background-color: ${({ theme }) => theme.palette.black.tint};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    /* display: flex; */
  }
`;

export const SocialIcon = styled.div`
  width: 24px;
  height: 24px;
`;

export const MobileNavLinks = styled.div`
  display: grid;
  grid-gap: 24px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Actions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  justify-content: end;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-gap: 16px;
  }
`;

export const NotificationButton = styled.button`
  box-sizing: border-box;
  display: inline-grid;
  place-items: center;
  align-self: center;
  justify-self: center;
  width: 56px;
  height: 56px;
  aspect-ratio: 1;
  border-radius: 48px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.palette.white.main};
  color: ${({ theme }) => theme.palette.white.main};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 32px;
    height: 32px;
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.mint.tint};
    border-color: ${({ theme }) => theme.palette.mint.tint};
    color: ${({ theme }) => theme.palette.black.main};
  }

  &:focus {
    border: 3px solid ${({ theme }) => theme.palette.white.main};
  }

  &:focus-visible {
    outline-color: ${({ theme }) => theme.palette.white.main};
  }

  &:disabled {
    color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};
    border-color: ${({ theme }) => `${theme.palette.white.main}${theme.opacity.half}`};

    background-color: transparent;
    cursor: not-allowed;
  }

  svg path {
    fill: currentColor;
  }
`;

export const WalletButton = styled(SecondaryButton)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 32px;
    font-size: 14px;
    padding: 4px 16px;
  }

  &:hover,
  &:focus {
    svg {
      circle {
        fill: currentColor;
      }
    }
  }
`;
