import React from "react";

type CloseIconProps = {
  color: string;
  className?: string;
  onClick: () => void;
};

const CloseIcon: React.FC<CloseIconProps> = ({ color, className, onClick }: CloseIconProps) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <title>Icons/close-blue</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="Icons/close-blue" stroke={color} strokeWidth="2">
        <line x1="2.5" y1="2.5" x2="21.5" y2="21.5" id="Line-4"></line>
        <line x1="2.5" y1="21.5" x2="21.5" y2="2.5" id="Line"></line>
      </g>
    </g>
  </svg>
);

export default CloseIcon;
